<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[
        { name: 'Inicio' },
        { name: 'Clasificaciones' },
        { name: 'Listado de clasificaciones' }
      ]"
    />
    <v-card style="border-radius:10px 10px 0 0;">
      <br />
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Listado de Clasificaciones</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CreateClasificacion' }" color="primary">
          Agregar Clasificación
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-data-table
          :headers="[{ text: 'Nombre', value: 'nombre' }, { text: 'Descripción', value: 'descripcion'}]"
          :items="clasificaciones"
          :loading="loadingClasificaciones"
          :rows-per-page-items="[10, 25, 35, 50]"
          class="elevation-1"
        >
          <tr slot="items" slot-scope="props">
            <td class="px-3">
              {{ props.item.nombre }}
            </td>
            <td class="px-3">
              {{ props.item.descripcion }}
            </td>
            <!-- <td class="px-3">
              <v-btn
                class="ma-0"
                :to="{ name: 'EditClasificacion', params: { id: props.item.id } }"
                small
                icon
                flat
                color="info"
              >
                <v-icon small>
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                        class="ma-0"
                        small
                        icon
                        flat
                        @click="openModalDeleteClasificacion(props.item)"
                        color="error"
                      >
                        <v-icon small>
                          delete
                        </v-icon>
                      </v-btn>
            </td> -->
          </tr>
        </v-data-table>
      </v-container>
    </v-card>
    <ModalDelete />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de clasificaciones" };
  },

  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission"),
    ModalDelete: () => import("@/views/clasificaciones/ModalDeleteClasificacion")
  },
  data() {
    return {};
  },

  computed: {
    ...mapState({
      clasificaciones: state => state.clasificaciones.clasificaciones,
      loadingClasificaciones: state => state.clasificaciones.loadingClasificaciones
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getClasificaciones();
  },
  methods: {
    ...mapActions({
      getClasificaciones: "clasificaciones/getClasificaciones",
      replaceShowModalDeleteClasificacion: "clasificaciones/replaceShowModalDeleteClasificacion",
      replaceCurrentClasificacion: "clasificaciones/replaceCurrentClasificacion"
    }),
    openModalDeleteClasificacion(clasificacion) {
      this.replaceCurrentClasificacion({ clasificacion });
      this.replaceShowModalDeleteClasificacion({ status: true });
    }
  }
};
</script>
